<template>
    <div class="home-box home-box--with-lines" id="home-box--wed-offer" v-if="display">
        <h3 class="decorated"><img v-bind:src="parse_header_image" alt=""><span>{{ $t('frontend.wednesday_crazy_sales_area') }}</span></h3>
        <div class="home-products-container">
            <div class="wed swiper-container">
                <div class="wed swiper-wrapper">

                    <!-- Loading Bubble -->
                    <div class="lds-grid-wrapper" v-if="loading" style="height: 600px">
                        <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>

                    <div class="wed swiper-slide product-box" v-for="(product, index) in products">
                        <a v-bind:href="product.url"><img v-bind:src="product.product_image" alt=""></a>
                        <ul>
                            <li class="product-details-tags">
                                <a href="javascript:return(0)" class="tag" v-bind:style="'background: '+product.type_color" v-if="product.type_color != null">{{ product.type_name }}</a>
                                <a href="javascript:return(0)" class="tag" v-bind:style="'background: '+product.version_color" v-if="product.type_color != null">{{ product.version_name }}</a>
                            </li>

                            <li><a v-bind:href="product.url" v-line-clamp:22="2" style="min-block-size: 48px;">{{ product.product_name }}</a></li>
                            <!-- If sale  -->
                            <template v-if="product.sales_price > 0">
                                <li class="origprice">{{ $t('frontend.products_pre_sales_price') }} <span style="text-decoration: line-through"> {{ currency_full }}{{ product.price }} </span></li>
                                <li class="offerprice">{{ $t('frontend.products_sales_price') }} {{ currency_full }}{{ product.sales_price }}</li>
                                <li class="origprice" v-if="product.end_promote_date > 0">{{ $t('frontend.sales_time_left') }} <span class="blueword">{{ sales_remaining_time[index] }}</span></li>
                                <li class="origprice" v-else>&nbsp;</li>
                                <!--<li class="origprice">尚餘 <span class="blueword"> 8件 </span></li>-->
                            </template>
                            <!-- If use normal price -->
                            <template v-else-if="product.sales_price == 0">
                                <li class="origprice">&nbsp;</li>
                                <li class="offerprice">{{ currency_full }}{{ product.price }}</li>
                                <li class="origprice">&nbsp;</li>
                            </template>
                        </ul>
                    </div>

                </div>
                <div class="wed swiper-button-next"></div>
                <div class="wed swiper-button-prev"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import moment_duration_format from "moment-duration-format";

    moment_duration_format(moment);

    export default {
        name: "wed_sales_area",
        props: [
            'parse_header_image',
            'parse_route',
            'viewing_product_type',
        ],
        data: function() {
            return {
                current_type: 'all',
                products: null,

                currency_short: null,
                currency_full: null,

                current_page: -1,
                last_page: -2,

                page_url: '#',

                route: null,
                loading: false,
                load_more: false,
                display: false,

                now: null
            }
        },
        created: function(){
            this.now = this.$root.$moment();
            var current_instance = this;
            setInterval(function () {
                current_instance.now = moment();
            }, 1000)
        },
        watch: {
            //methods call when data has been changed
            parse_route: function(new_val, old_val){
                this.route = new_val;
                this.load_more = true;
                this.loadData();
            },
            viewing_product_type: function(new_val, old_val){
                this.current_type = new_val;
            }
        },
        computed: {
            sales_remaining_time: function(){
                var now = this.now;
                var current_instance = this;
                return this.products.map(function(product){
                    var expire_on = moment.unix(parseInt(product.end_promote_date));

                    if (expire_on > now) {
                        var ms = expire_on.diff(now, 'milliseconds', true);
                        var duration = moment.duration(ms, 'milliseconds');
                        // return duration.asMilliseconds();
                        return duration.format(current_instance.$root.$t('frontend.sales_time_countdown_format'));
                    } else {
                        return current_instance.$root.$t('frontend.sales_expired');
                    }
                });

            }
        },
        methods: {
            loadData: function() {
                this.loading = true;
                var route = this.route;
                if (this.current_page === null && this.last_page === null) {
                    route = route + '?page=1';
                } else {
                    route = route + '?page=' + (this.current_page + 1);
                }

                if (this.current_page !== this.last_page) {
                    // this.startLoading();
                    var component = this;
                    if (this.load_more === true) {
                        axios.post(route, {
                            viewing_type: this.current_type,
                            csrf_token: this.csrf_field
                        })
                            .then(function (response){
                                // this.endLoading();

                                component.current_page = response.data.current_page;
                                component.last_page = response.data.last_page;

                                if (response.data.current_page === response.data.last_page || response.data.has_more_pages == false) {
                                    component.load_more = false;
                                }

                                component.products = response.data.data;

                                component.currency_short = response.data.currency_short;
                                component.currency_full = response.data.currency_full;

                                component.page_url = response.data.page_url;

                                component.loading = false;

                                if (component.products.length >= 1) {
                                    component.display = true;
                                }
                            })
                            .catch(function (error){
                                console.log(error);
                            })
                        ;
                    }
                } else {
                    this.load_more = false;
                }
            }
        }
    }
</script>