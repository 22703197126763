import { render, staticRenderFns } from "./LimitedTimeSalesArea.vue?vue&type=template&id=110bb488&scoped=true&"
import script from "./LimitedTimeSalesArea.vue?vue&type=script&lang=js&"
export * from "./LimitedTimeSalesArea.vue?vue&type=script&lang=js&"
import style0 from "./LimitedTimeSalesArea.vue?vue&type=style&index=0&id=110bb488&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110bb488",
  null
  
)

export default component.exports