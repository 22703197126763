<template>
    <div v-bind:class="['home-box', 'home-box--2col', is_active]" v-bind:id="parse_component_id">
        <h3>{{ parse_title }}</h3>

        <div class="box-group-container home-products-container fullwidth" style="display: flex; justify-content: center; align-items: center; height: 375px;" v-if="products.length == 0">
            <div class="box-group">
                <div class="box-group swiper-wrapper">
                    <!-- Loading Bubble -->
                    <div class="lds-grid-wrapper" v-if="loading">
                        <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>

                    <template v-if="current_page !== -1 && !loading">
                        <div class="box-group" style="width: 100%; display: flex; justify-content: center; align-items: center; padding: 15px;" v-if="products.length === 0">
                            <p style="text-align: left;">{{ $t('frontend.general_product_area_empty') }}</p>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="box-group-container home-products-container fullwidth" v-bind:style="display_style">
            <div class="box-group swiper-container swiper-container-fullwidth">
                <div class="box-group swiper-wrapper">
                    <div class="box-group swiper-slide product-box" v-if="!loading" v-for="(product, index) in products">
                        <div class="product-container">
                            <div class="product-img">
                                <a v-bind:href="product.url">
                                    <template v-if="product.is_2nd_hand == 1">
                                        <div class="triangle-tag secondhand">
                                            <span>{{ $t('frontend.products_2nd_hand') }}</span>
                                        </div>
                                    </template>
                                    <template v-else-if="product.is_pre_order == 1">
                                        <div class="triangle-tag pre-order">
                                            <span>{{ $t('frontend.products_pre_order') }}</span>
                                        </div>
                                    </template>
<!--                                    <template v-else-if="product.is_restock == 1">-->
<!--                                        <div class="triangle-tag restock">-->
<!--                                            <span>{{ $t('frontend.products_restock') }}</span>-->
<!--                                        </div>-->
<!--                                    </template>-->
                                    <img v-bind:src="product.product_image" alt="">
                                </a>
                                <div class="product-img-btn">
                                    <button type="button" class="btn btn-mini btn-blue" v-on:click="addToCart($event, product.id, 1, {})"><i class="icon icon-cart"></i></button>
                                    <button type="button" class="btn btn-mini btn-red" v-on:click="addToFav($event, product.id, 1, {})"><i class="icon icon-favourite"></i></button>
                                </div>
                            </div>
                            <div class="product-details">
                                <ul>
                                    <li class="product-details-tags">
                                        <a href="javascript:return(0)" class="tag" v-bind:style="'background: '+product.type_color" v-if="product.type_color != null">{{ product.type_name }}</a>
                                        <a href="javascript:return(0)" class="tag" v-bind:style="'background: '+product.version_color" v-if="product.type_color != null">{{ product.version_name }}</a>
                                    </li>
                                    <li><a v-bind:href="product.url" v-line-clamp:22="2" style="min-block-size: 48px;">{{ product.product_name }}</a></li>

                                    <!-- If sale  -->
                                    <template v-if="product.sales_price > 0">
                                        <li class="origprice">{{ $t('frontend.products_pre_sales_price') }} <span style="text-decoration: line-through"> {{ currency_full }}{{ product.price }} </span></li>
                                        <li class="offerprice">{{ $t('frontend.products_sales_price') }} {{ currency_full }}{{ product.sales_price }}</li>
                                        <li class="origprice" v-if="product.end_promote_date > 0">{{ $t('frontend.sales_time_left') }} <span class="blueword">{{ sales_remaining_time[index] }}</span></li>
                                        <li class="origprice" v-else>&nbsp;</li>
                                    </template>
                                    <!-- If use normal price -->
                                    <template v-else-if="product.sales_price == 0">
                                        <li class="origprice">&nbsp;</li>
                                        <li class="offerprice">{{ currency_full }}{{ product.price }}</li>
                                        <li class="origprice">&nbsp;</li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="more-btn-container">
            <a v-bind:href="page_url" class="btn btn-blue btn-sm"><i class="icon-more"></i> {{ $t('frontend.product_type_more') }}</a>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import moment_duration_format from "moment-duration-format";
    import CartMixin from "../../../cart/cart";

    moment_duration_format(moment);

    export default {
        name: "full_slider_area",
        mixins: [CartMixin],
        props: [
            'parse_active_tab',
            'parse_component_id',
            'parse_title',
            'parse_route',
            'viewing_product_type',
            'parse_add_to_cart_route',
            'parse_add_to_fav_route',
        ],
        data: function() {
            return {
                current_type: 'all',
                products: [],

                currency_short: null,
                currency_full: null,

                current_page: -1,
                last_page: -2,

                page_url: '#',

                route: null,
                loading: false,
                load_more: false,
                display: false,
                loaded_style: 'display: flex; justify-content: center; align-items: center;',
                loading_style: 'display: none',

                now: null,

                active_tab: null,

                add_to_cart_route: null,
                add_to_fav_route: null,
            }
        },
        created: function(){
            this.now = this.$root.$moment();
            var current_instance = this;
            setInterval(function () {
                current_instance.now = moment();
            }, 1000)
        },
        watch: {
            //methods call when data has been changed
            parse_route: function(new_val, old_val){
                this.route = new_val;
                this.load_more = true;
                this.loadData();
            },
            viewing_product_type: function(new_val, old_val){
                this.current_type = new_val;
                this.products = [];
                this.loadData();
            },
            parsed_add_to_cart_route: function(new_val, old_val){
                this.add_to_cart_route = new_val;
            },
            parsed_add_to_fav_route: function(new_val, old_val){
                this.add_to_fav_route = new_val;
            }
        },
        computed: {
            sales_remaining_time: function(){
                var now = this.now;
                var current_instance = this;
                return this.products.map(function(product){
                    var expire_on = moment.unix(parseInt(product.end_promote_date));

                    if (expire_on > now) {
                        var ms = expire_on.diff(now, 'milliseconds', true);
                        var duration = moment.duration(ms, 'milliseconds');
                        // return duration.asMilliseconds();
                        return duration.format(current_instance.$root.$t('frontend.sales_time_countdown_format'));
                    } else {
                        return current_instance.$root.$t('frontend.sales_expired');
                    }
                });
            },
            display_style: function (){
                if(this.loading === false && this.products.length > 0){
                    return this.loaded_style;
                } else {
                    return this.loading_style;
                }
            },
            is_active: function (){
                if(this.parse_active_tab == this.parse_component_id){
                    return 'active';
                } else {
                    return '';
                }
            }
        },
        methods: {
            loadData: function() {
                this.loading = true;
                var route = this.route;


                // if (this.current_page === null && this.last_page === null) {
                //     route = route + '?page=1';
                // } else {
                //     route = route + '?page=' + (this.current_page + 1);
                // }
                // if (this.current_page !== this.last_page) {
                //     if (response.data.current_page === response.data.last_page || response.data.has_more_pages == false) {
                //         this.load_more = false;
                //     }
                // } else {
                //     this.load_more = false;
                // }
                // this.startLoading();
                var component = this;
                if (this.load_more === true) {
                    axios.post(route, {
                        viewing_type: this.current_type,
                        csrf_token: this.csrf_field
                    }).then(function(response){
                        // this.endLoading();

                        component.current_page = response.data.current_page;
                        component.last_page = response.data.last_page;

                        component.products = response.data.data;

                        component.currency_short = response.data.currency_short;
                        component.currency_full = response.data.currency_full;

                        component.page_url = response.data.page_url;

                        component.loading = false;

                        if (component.products.length >= 1) {
                            component.display = true;
                        }
                    }).catch(function(error) {
                        console.log(error);
                    });
                }
                // if (this.load_more === true) {
                //     axios.post(route, {
                //         viewing_type: this.current_type,
                //         csrf_token: this.csrf_field
                //     }).then((response) => {
                //             // this.endLoading();
                //
                //             this.current_page = response.data.current_page;
                //             this.last_page = response.data.last_page;
                //
                //             this.products = response.data.data;
                //
                //             this.currency_short = response.data.currency_short;
                //             this.currency_full = response.data.currency_full;
                //
                //             this.page_url = response.data.page_url;
                //
                //             this.loading = false;
                //
                //             if (this.products.length >= 1) {
                //                 this.display = true;
                //             }
                //     }).catch((error) => {
                //             console.log(error);
                //     });
                // }
            },
        }
    };


</script>