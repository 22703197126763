<template>
    <div class="index-loader-right-area">

        <div>
            <limited_time_sales_area
                v-bind:viewing_product_type="viewing_product_type"
                v-bind:parse_route="limited_time_sales_area_route"
                v-bind:parse_data="limited_time_sales_area_data"
                v-bind:parse_title="( $t('frontend.limited_time_sales_area') )"
                v-bind:parse_component_id="'home-box--latest'"
                v-bind:parse_active_tab="active_tab"
                v-bind:parse_add_to_cart_route="add_to_cart_route"
                v-bind:parse_add_to_fav_route="add_to_fav_route"
            ></limited_time_sales_area>
        </div>

        <div class="home-box-group">

          <!-- New Arrivals Area -->
          <horizontal_slider_area
              v-bind:viewing_product_type="viewing_product_type"
              v-bind:parse_data="new_arrival_area_data"
              v-bind:parse_route="new_arrival_area_route"
              v-bind:parse_title="( $t('frontend.new_arrivals_area') )"
              v-bind:parse_component_id="'home-box--latest'"
              v-bind:parse_active_tab="active_tab"
              v-bind:parse_add_to_cart_route="add_to_cart_route"
              v-bind:parse_add_to_fav_route="add_to_fav_route"
          ></horizontal_slider_area>

            <!-- Hot Items Area -->
            <horizontal_slider_area
                    v-bind:viewing_product_type="viewing_product_type"
                    v-bind:parse_data="hot_items_area_data"
                    v-bind:parse_route="hot_items_area_route"
                    v-bind:parse_title="( $t('frontend.hot_items_area') )"
                    v-bind:parse_component_id="'home-box--hot-sales'"
                    v-bind:parse_active_tab="active_tab"
                    v-bind:parse_add_to_cart_route="add_to_cart_route"
                    v-bind:parse_add_to_fav_route="add_to_fav_route"
            ></horizontal_slider_area>  <!-- half_slider_area -->

            <!-- Recommended Area -->
            <horizontal_slider_area
                    v-bind:viewing_product_type="viewing_product_type"
                    v-bind:parse_data="recommendation_area_data"
                    v-bind:parse_route="recommendation_area_route"
                    v-bind:parse_title="( $t('frontend.recommendation_area') )"
                    v-bind:parse_component_id="'home-box--recommended'"
                    v-bind:parse_active_tab="active_tab"
                    v-bind:parse_add_to_cart_route="add_to_cart_route"
                    v-bind:parse_add_to_fav_route="add_to_fav_route"
            ></horizontal_slider_area>

            <!--<div class="half-container">-->
                <!-- Preorder Area -->
                <horizontal_slider_area
                    v-bind:viewing_product_type="viewing_product_type"
                    v-bind:parse_data="preorder_area_data"
                    v-bind:parse_route="preorder_area_route"
                    v-bind:parse_active_tab="active_tab"
                    v-bind:parse_title="( $t('frontend.preorder_area') )"
                    v-bind:parse_component_id="'home-box--preorder'"
                    v-bind:parse_add_to_cart_route="add_to_cart_route"
                    v-bind:parse_add_to_fav_route="add_to_fav_route"
                ></horizontal_slider_area>

            <!-- Restocked Area -->
            <!-- Backend not implemented -->
            <!-- Backend not implemented -->
            <!-- Backend not implemented -->
            <horizontal_slider_area
                    v-bind:viewing_product_type="viewing_product_type"
                    v-bind:parse_data="restock_area_data"
                    v-bind:parse_route="restock_area_route"
                    v-bind:parse_title="( $t('frontend.restocked_area') )"
                    v-bind:parse_component_id="'home-box--host-restock'"
                    v-bind:parse_active_tab="active_tab"
                    v-bind:parse_add_to_cart_route="add_to_cart_route"
                    v-bind:parse_add_to_fav_route="add_to_fav_route"
            ></horizontal_slider_area>

                <!-- Sales Area -->
                <horizontal_slider_area
                        v-bind:viewing_product_type="viewing_product_type"
                        v-bind:parse_data="sales_area_data"
                        v-bind:parse_route="sales_area_route"
                        v-bind:parse_active_tab="active_tab"
                        v-bind:parse_title="( $t('frontend.sales_area') )"
                        v-bind:parse_component_id="'home-box--price-drops'"
                        v-bind:parse_add_to_cart_route="add_to_cart_route"
                        v-bind:parse_add_to_fav_route="add_to_fav_route"
                ></horizontal_slider_area>
            <!--</div>-->

            <!--<div class="half-container">-->
                <!-- Clearance Area -->
                <horizontal_slider_area
                        v-bind:viewing_product_type="viewing_product_type"
                        v-bind:parse_data="clearance_area_data"
                        v-bind:parse_route="clearance_area_route"
                        v-bind:parse_active_tab="active_tab"
                        v-bind:parse_title="( $t('frontend.clearance_area') )"
                        v-bind:parse_component_id="'home-box'"
                        v-bind:parse_add_to_cart_route="add_to_cart_route"
                        v-bind:parse_add_to_fav_route="add_to_fav_route"
                ></horizontal_slider_area>

                <!-- Second Hand Area -->
                <horizontal_slider_area
                        v-bind:viewing_product_type="viewing_product_type"
                        v-bind:parse_data="second_hand_area_data"
                        v-bind:parse_route="second_hand_area_route"
                        v-bind:parse_active_tab="active_tab"
                        v-bind:parse_title="( $t('frontend.second_hands_area') )"
                        v-bind:parse_component_id="'home-box'"
                        v-bind:parse_add_to_cart_route="add_to_cart_route"
                        v-bind:parse_add_to_fav_route="add_to_fav_route"
                ></horizontal_slider_area>
            <!--</div>-->

            <your_favourite_slider_area
                    v-bind:parse_route="your_favourite_route"
                    v-bind:parse_title="( $t('frontend.your_favourites') )"
                    v-bind:parse_component_id="'home-box'"
                    v-bind:parse_add_to_cart_route="add_to_cart_route"
                    v-bind:parse_add_to_fav_route="add_to_fav_route"
            ></your_favourite_slider_area>

            <history_slider_area
                    v-bind:parse_route="browser_history_route"
                    v-bind:parse_title="( $t('frontend.browse_history') )"
                    v-bind:parse_component_id="'home-box'"
                    v-bind:parse_add_to_cart_route="add_to_cart_route"
                    v-bind:parse_add_to_fav_route="add_to_fav_route"
            ></history_slider_area>
        </div>
    </div>
</template>

<script>
    import LimitedTimeSalesArea from '../components/LimitedTimeSalesArea';
    import PopularConsole from '../components/PopularConsoles';
    import FullSliderArea from '../components/FullSliderArea';
    import HalfSliderArea from '../components/HalfSliderArea';
    import Gray_slider_area from "../components/GraySliderArea";
    import YourFavoriteSliderArea from '../components/YourFavoriteSliderArea';
    import History_slider_area from "../components/HistorySliderArea";
    import HorizontalSliderArea from "../components/HorizontalSliderArea";

    export default {
        name: 'index_loader_right',
        props: [
            'limited_time_sales_area_route',
            'popular_consoles_route',
            'hot_items_area_route',
            'new_arrival_area_route',
            'restock_area_route',
            'recommendation_area_route',
            'preorder_area_route',
            'sales_area_route',
            'clearance_area_route',
            'second_hand_area_route',
            'your_favourite_route',
            'browser_history_route',

            'add_to_cart_route',
            'add_to_fav_route',

            'limited_time_sales_area_data',
            'new_arrival_area_data',
            'hot_items_area_data',
            'recommendation_area_data',
            'preorder_area_data',
            'restock_area_data',
            'sales_area_data',
            'clearance_area_data',
            'second_hand_area_data',
            'product_types',

            'viewing_product_type',
        ],
        components: {
            gray_slider_area: Gray_slider_area,
            limited_time_sales_area: LimitedTimeSalesArea,
            popular_consoles: PopularConsole,
            full_slider_area: FullSliderArea,
            half_slider_area: HalfSliderArea,
            history_slider_area: History_slider_area,
            your_favourite_slider_area: YourFavoriteSliderArea,
            horizontal_slider_area: HorizontalSliderArea,
        },
        data: function(){
            return {
                active_tab: 'home-box--hot-sales',
            }
        },
        methods: {
            // switchTab: function($event, tab_name){
            //     var target = $event.target;
            //     $('.home-box-group-tabs').removeClass('active');
            //     $('.home-box-group-tabs a').removeClass('active');
            //     $(target).parent().addClass('active');
            //     $(target).addClass('active');
            //     this.active_tab = tab_name;
            // }
        },
        watch: {
            limited_time_sales_area_route: function(new_val, old_val){
                //received route props
            }
        }

    }
</script>