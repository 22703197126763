<template>
    <div>
        <button class="btn btn-pink d-lg-none" data-href="#console-options-list" id="console-options-btn" v-on:click="showMenu($event)"><i class="icon icon-menu"></i></button>

        <ul id="console-options-list">
            <li v-bind:class="[ current_viewing == 'all' ? 'active' : '' ]"><a href="#" v-on:click="switchType($event, 'all')" v-bind:mode="'all'" v-bind:ref="'product_type_all'">{{ $t('frontend.product_type_all') }}</a></li>
            <li v-for="(type, index) in product_types" v-bind:class="[ current_viewing == type.id ? 'active' : '' ]">
                <a href="#" v-on:click="switchType($event, type.id)" v-bind:mode="type.id" v-bind:ref="'product_type_'+type.id">{{ type.short_name }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    import axios from "axios";

    function scrollToPosition(element){
        var eleScrollTop = $(element).position();
        eleScrollTop.top = eleScrollTop.top+500;
        console.log(eleScrollTop);
        $(document).scrollTo(eleScrollTop, 300);
    }

    $(document).on('click', '#console-options-list', function(){
        // indexCalendar.$emit('calendarOnChange', indexCalendar.dpPermanent.state, true);
        var element = $('#home-box--hot-sales');
        setTimeout(function(){
            scrollToPosition(element);
        }, 400);
    });


    export default {
        name: "index_loader_bar",
        props: ['index_loader_product_types_route'],
        data: function(){
            return {
                current_viewing: 'all',

                product_types: null,

                route: null,
                current_page: -1,
                last_page: -2,
                load_more: true,
                loading: false
            }
        },
        computed: {

        },
        methods: {
            showMenu: function($event){
                $event.preventDefault();
                $('.console-options').toggleClass('expanded');
            },
            loadData: function(){

                this.loading = true;
                var route = this.route;
                if(this.current_page === null && this.last_page === null){
                    route = route+'?page=1';
                } else {
                    route = route+'?page='+(this.current_page+1);
                }

                if (this.current_page !== this.last_page) {
                    // this.startLoading();
                    var component = this;
                    if(this.load_more === true) {
                        axios.post(route, {
                            csrf_token: this.csrf_field,
                        })
                            .then(function (response) {
                                // this.endLoading();

                                component.current_page = response.data.current_page;
                                component.last_page = response.data.last_page;

                                if (response.data.current_page === response.data.last_page || response.data.has_more_pages == false) {
                                    component.load_more = false;
                                }

                                component.product_types = response.data.data;

                                component.loading = false;
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                        ;
                    }
                } else {
                    this.load_more = false;
                }
            },
            switchType: function($event, type){
                var instance = this;
                console.log(type);
                $event.preventDefault();
                this.current_viewing = type;
                $('.console-options').toggleClass('expanded');
                this.$root.$emit('switchType', {'type':type});
            }
        },
        watch: {
            index_loader_product_types_route: function(new_val, old_val){
                this.route = new_val;
                this.loadData();
            }
        }
    }
</script>