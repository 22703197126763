import axios from "axios";
import store from "../../store/main_store";
import VueInternationalization from 'vue-i18n';
// import VueLazyLoad from 'vue-lazyload';
import lineClamp from 'vue-line-clamp';
import trans from '../../../js/vue-i18n-locales.generated';
import moment from "vue-moment";
import MomentDurationFormat from "moment-duration-format";
import IndexLoaderRight from './wrapper/IndexLoaderRight';
import IndexLoaderBar from './wrapper/IndexLoaderBar';
import WedSalesArea from './components/WedSliderArea';
import CartMixin from "../../cart/cart";

// MomentDurationFormat(moment);

// Vue.use(VueLazyLoad);
// Need to configure later

Vue.use(VueInternationalization);
Vue.use(moment);
Vue.use(lineClamp);

const locale = $('#index-loader-right').attr('locale');

const i18n = new VueInternationalization({
    locale: locale,
    messages: trans
});

var index_loader_right = new Vue({
    el: '#index-loader-right',
    // mixins: [CartMixin],
    i18n,
    data: {
        limited_time_sales_area_route: null,
        popular_consoles_route: null,
        hot_items_area_route: null,
        new_arrival_area_route: null,
        restock_area_route: null,
        recommendation_area_route: null,
        preorder_area_route: null,
        sales_area_route: null,
        clearance_area_route: null,
        second_hand_area_route: null,
        your_favourite_route: null,
        browser_history_route: null,
        out_of_stock_subscribe: null,

        add_to_cart_route: null,
        add_to_fav_route: null,

        limited_time_sales_area_data: null,
        new_arrival_area_data: null,
        hot_items_area_data: null,
        recommendation_area_data: null,
        preorder_area_data: null,
        restock_area_data: null,
        sales_area_data: null,
        clearance_area_data: null,
        second_hand_area_data: null,


        viewing_product_type: 'all',

        csrf_field: null,
        locale: null,

    },
    components: {
        index_loader_right: IndexLoaderRight
    },
    methods: {
        changeViewingProductType: function(incoming){
            this.viewing_product_type = incoming;
        }
    },
    mounted: function (){
        // this.doctors = JSON.parse(this.$el.getAttribute('data_doctors'));
        var data = this.$el.getAttribute('limited_time_sales_area_data');
        if (data){
            this.limited_time_sales_area_data = JSON.parse(data);
        }        
        data = this.$el.getAttribute('new_arrival_area_data');
        if (data){
            this.new_arrival_area_data = JSON.parse(data);
        }
        data = this.$el.getAttribute('hot_items_area_data');
        if (data){
            this.hot_items_area_data = JSON.parse(data);
        }
        data = this.$el.getAttribute('recommendation_area_data');
        if (data){
            this.recommendation_area_data = JSON.parse(data);
        }
        data = this.$el.getAttribute('preorder_area_data');
        if (data){
            this.preorder_area_data = JSON.parse(data);
        }
        data = this.$el.getAttribute('restock_area_data');
        if (data){
            this.restock_area_data = JSON.parse(data);
        }
        data = this.$el.getAttribute('sales_area_data');
        if (data){
            this.sales_area_data = JSON.parse(data);
        }
        data = this.$el.getAttribute('clearance_area_data');
        if (data){
            this.clearance_area_data = JSON.parse(data);
        }
        data = this.$el.getAttribute('second_hand_area_data');
        if (data){
            this.second_hand_area_data = JSON.parse(data);
        }

        this.limited_time_sales_area_route = this.$el.getAttribute('limited_time_sales_area_route');
        this.popular_consoles_route = this.$el.getAttribute('popular_consoles_route');
        this.hot_items_area_route = this.$el.getAttribute('hot_items_area_route');
        this.new_arrival_area_route = this.$el.getAttribute('new_arrival_area_route');
        this.restock_area_route = this.$el.getAttribute('restock_area_route');
        this.recommendation_area_route = this.$el.getAttribute('recommendation_area_route');
        this.preorder_area_route = this.$el.getAttribute('preorder_area_route');
        this.sales_area_route = this.$el.getAttribute('sales_area_route');
        this.clearance_area_route = this.$el.getAttribute('clearance_area_route');
        this.second_hand_area_route = this.$el.getAttribute('second_hand_area_route');
        this.your_favourite_route = this.$el.getAttribute('your_favourite_route');
        this.browser_history_route = this.$el.getAttribute('browser_history_route');
        this.out_of_stock_subscribe = this.$el.getAttribute('out_of_stock_subscribe');

        this.add_to_cart_route = this.$el.getAttribute('add_to_cart_route');
        this.add_to_fav_route = this.$el.getAttribute('add_to_fav_route');
        

        this.csrf_field = this.$el.getAttribute('csrf_token');
        this.locale = locale;

        this.$on('changeViewingProductType', function (data){
            this.changeViewingProductType(data.type);
        });
    }
});

var index_loader_bar = new Vue({
    el: '#index-loader-bar',
    i18n,
    data: {
        index_loader_product_types_route: null,

        csrf_field: null,
        locale: null,

    },
    components: {
        index_loader_bar: IndexLoaderBar
    },
    methods: {
        switchType: function(data){
            index_loader_right.changeViewingProductType(data.type);
        }
    },
    mounted: function (){
        this.index_loader_product_types_route = this.$el.getAttribute('index_loader_product_types_route');

        this.csrf_field = this.$el.getAttribute('csrf_token');
        this.locale = locale;

        this.$on('switchType', function(data){
            this.switchType(data);
        })
    }
});

var wed_sales_area = new Vue({
    el: '#wed_sales_area',
    i18n,
    data: {
        header_image: null,
        wed_sales_area_route: null,

        viewing_product_type: 'all',

        csrf_field: null,
        locale: null
    },
    components: {
        wed_sales_area: WedSalesArea
    },
    methods: {
        changeViewingProductType: function(incoming){
            this.viewing_product_type = incoming;
        }
    },
    mounted: function (){
        this.header_image = this.$el.getAttribute('header_image');
        this.wed_sales_area_route = this.$el.getAttribute('wed_sales_area_route');

        this.csrf_field = this.$el.getAttribute('csrf_token');
        this.locale = locale;

        this.$on('changeViewingProductType', function (data){
            this.changeViewingProductType(data.type);
        });
    }
});