<template>
    <div class="home-box d-none d-lg-block mb-5" id="home-box--hot-consoles" v-if="display">
        <h3>{{ $t('frontend.popular_consoles') }}</h3>
        <ul>
            <li v-for="(item, index) in list_items">
                <a v-bind:href="item.url"><img v-bind:src="item.filename" alt=""></a>
            </li>
        </ul>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "popular_consoles",
        props: [
            'popular_consoles_route'
        ],
        data: function(){
            return {

                list_items: null,

                component_route: null,
                current_page: -1,
                last_page: -2,
                loading: false,
                load_more: false,
                display: false
            }
        },
        watch: {
            popular_consoles_route: function(new_val, old_val){
                this.component_route = new_val;
                this.load_more = true;
                this.loadData();
            }
        },
        methods: {
            loadData: function () {
                this.loading = true;
                var route = this.component_route;
                if (this.current_page === null && this.last_page === null) {
                    route = route + '?page=1';
                } else {
                    route = route + '?page=' + (this.current_page + 1);
                }

                if (this.current_page !== this.last_page) {
                    // this.startLoading();
                    var component = this;
                    if (this.load_more === true) {
                        axios.post(route, {
                            csrf_token: this.csrf_field,
                        })
                            .then(function (response){
                                // this.endLoading();

                                component.current_page = response.data.current_page;
                                component.last_page = response.data.last_page;

                                if (response.data.current_page === response.data.last_page || response.data.has_more_pages == false) {
                                    component.load_more = false;
                                }

                                component.list_items = response.data.data;

                                component.loading = false;

                                if (component.list_items.length >= 1) {
                                    component.display = true;
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                        ;
                    }
                } else {
                    this.load_more = false;
                }
            }
        }
    }
</script>